import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WelcomePage"),
  },
  {
    path: "/admin-pnl",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminVIew"),
  },
  {
    path: "/admin-login",
    name: "admin-login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminLogin"),
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainView"),
    children: [
      {
        path: "/",
        name: "create",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CreateView"),
      }
    ],
  },
];

const router = new VueRouter({
  mode: "history", // встановлення режиму "history"
  routes,
});

router.beforeEach((to, from, next) => {
  let currentCode = JSON.parse(localStorage.getItem("currentCodeLego"));
  let adminToken = localStorage.getItem("adminUserTokenLego");
  console.log(to.path);
  if (to.path == "/admin-login" || to.path == "/admin-pnl") {
    if (!adminToken && to.path == "/admin-login") {
      next();
      return;
    }
    if (adminToken && to.path == "/admin-login") {
      next("/admin-pnl");
      return;
    }
    if (!adminToken && to.path == "/admin-pnl") {
      next("/admin-login");
      return;
    }
    if (adminToken && to.path == "/admin-pnl") {
      next();
      return;
    }
  } else {
    if (!currentCode && to.path !== "/welcome") {
      next("/welcome");
      return;
    }
  }

  next();
});

export default router;
