<template>
  <v-container>
    <div class="header-wrp d-flex align-center justify-space-between">
      <div class="header-wrp__logo d-flex align-center">
        <img src="../images/logo_big.svg" />
      </div>
      <div class="header-wrp__actions d-flex align-center">
        <LangChange />
      </div>
    </div>
  </v-container>
</template>

<script>
import LangChange from "./LangChange.vue";
export default {
  components: {
    LangChange,
  },
};
</script>

<style lang="scss" scoped>
.header-wrp {
  margin-bottom: 22px;
  margin-top: 22px;
  &__logo {
    img {
      width: 250px;
    }
  }
}
</style>
