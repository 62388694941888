import axios from "axios";
import config from "./config";

const instanse = axios.create(config());

instanse.interceptors.request.use(
  (config) => {
    // config.headers["X-Locale"] = "en";
    config.headers["localtonet-skip-warning"] = `*`;
    const token = localStorage.getItem("userTokenLego");
    if (token) {
      config.headers["X-CODE-TOKEN"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instanse.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instanse;
