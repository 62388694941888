import { getVerifiedCodeInfo } from "../../api/api";
export default {
  namespaced: true,
  state: {
    currentLang: null,
    currentCode: null,
  },
  getters: {
    currentLang: (state) => state.currentLang,
    currentCode: (state) => state.currentCode,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    setLang({ commit }, lang) {
      commit("set", {
        type: "currentLang",
        items: lang,
      });
    },
    async getVerifiedCodeInfo({ commit }, params) {
      await getVerifiedCodeInfo(params).then((response) => {
        console.log(response);
        localStorage.setItem(
          "currentCodeLego",
          JSON.stringify(response.data.codeValue)
        );
        commit("set", { type: "currentCode", items: response.data });
      });
    },
  },
};
