import API from "./index";

export const checkVerifyCode = (id) => {
  return API.post(`/codes/${id}/verify`);
};
export const changeArt = (id, code, params) => {
  return API.put(`/codes/${code}/arts/${id}`, params);
};

export const selectedArt = (id, code) => {
  return API.put(`/lego/${code}/select/${id}`);
};

export const getVerifiedCodeInfo = (id) => {
  return API.get(`/codes/${id}/verified`);
};
export const getLegoSettings = () => {
  return API.get(`/lego-setting`);
};

export const getPdf = (code, id) => {
  return API.get(`/codes/${code}/arts/${id}/pdf`, {
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/pdf",
    },
  });
};

export const sendEmailPdf = (id, code, data, lang) => {
  return API.post(`/lego/${code}/mail/${id}?lang=${lang}`, data);
};

export const drawImages = (code, id) => {
  // /api/codes/4a8f-4a63/arts/11/draw
  return API.get(`/codes/${code}/arts/${id}/draw`);
};

export const handleDraw = (code, id) => {
  const token = localStorage.getItem("userTokenLego");
  return fetch(
    `https://string-art.herokuapp.com/api/codes/${code}/arts/${id}/draw`,
    {
      headers: {
        "X-CODE-TOKEN": token,
      },
    }
  );
};

export const postImage = (params) => {
  let bodyFormData = new FormData();

  bodyFormData.append("file", params.file);
  bodyFormData.append("legoSettingId", params.legoSettingId);

  return API.post(`lego/${params.code}/upload`, bodyFormData, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  });
};
