<template>
  <div
    class="lang-wrp"
    v-if="
      currentLang && currentLang.value && selectedLang && selectedLang.value
    "
  >
    <v-menu offset-y content-class="lang-menu">
      <template v-slot:activator="{ on, attrs }">
        <div class="lang-wrp__current">
          <img :src="selectedLang.img" v-bind="attrs" v-on="on" />
        </div>
      </template>
      <v-list class="lang-list">
        <template v-for="(lang, index) in langs">
          <v-list-item :key="index" v-if="lang.value !== selectedLang.value">
            <v-list-item-title @click="changeLang(lang)">
              <img :src="lang.img" />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      selectedLang: null,
      langs: [
        {
          value: "uk",
          img: require("@/images/ukraine-flag.png"),
        },
        {
          value: "pl",
          img: require("@/images/poland-flag.png"),
        },
        {
          value: "en",
          img: require("@/images/usa-flag.png"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("global", ["currentLang"]),
  },
  watch: {
    selectedLang(selectedLang) {
      this.setLang(selectedLang);
    },
  },
  created() {
    var userLang = '';
    if (localStorage.getItem("langLego")) {
      userLang = localStorage.getItem("langLego");
    } else {
      userLang = navigator.language || navigator.userLanguage;
    }
    
   

    switch (userLang) {
      case "uk":
        this.selectedLang = {
          value: "uk",
          img: require("@/images/ukraine-flag.png"),
        };
        break;
      case "pl":
        this.selectedLang = {
          value: "pl",
          img: require("@/images/poland-flag.png"),
        };
        break;

      default:
        this.selectedLang = {
          value: "en",
          img: require("@/images/usa-flag.png"),
        };
        break;
    }
  },
  methods: {
    ...mapActions("global", ["setLang"]),
    changeLang(lang) {
      console.log(lang.value);
      this.selectedLang = lang;
      localStorage.setItem("langLego", lang.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-wrp {
  &__current {
    display: flex;
    img {
      width: 40px;
    }
  }
}
.lang-menu {
  box-shadow: none;
}
.lang-list {
  background: none;
  width: 40px;
  padding-left: 4px;
  img {
    width: 30px;
  }
  .v-list-item {
    cursor: pointer;
    padding: 0;
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
